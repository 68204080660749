@import url("https://fonts.google.com/specimen/Rubik");
body {
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  /* padding-left: 4rem !important; */
  padding-right: 0 !important;
  box-sizing: border-box;
  background-color: white !important;
  /* background-image: url("") !important; */
  /* background: "rgba(0,0,0,0.9)"; */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./img/junglebay-background.png");
  /* rgba(0, 0, 0, 0.9); */
  /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4); */
  width: 100% !important;
  background-size: cover;
  /* height: 100%; */
  min-height: 110vh;
  width: 100% !important;
  background-position: center;
  font-family: rubik;
}
@font-face {
  font-family: Rubik;
  /* src: url("/public/Fonts/Rubik/Rubik-VariableFont_wght.ttf"); */
  src: url("/public/Fonts/Press_Start_2P/PressStart2P-Regular.ttf");
}
.font {
  font-family: Rubik;
}

.MMpwq {
  background-color: black !important;
}
