/* .dashboard-main-heading{
    padding-top: 8rem !important;
    padding-left: 10rem !important;
} */
.connect-wallet-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.connect-btn-icon {
  width: 10px;
  height: 10px;
  font-family: "Rubik" !important;
}
/* .connect-wallet-box-card{
    
} */
#colored-text-below-heading {
  color: #ffc100;
}
.web3-connect-btn {
  background: "#FFA370" !important;
}
